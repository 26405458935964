import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { constDefault } from '../const_default';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
interface serviceDataLedgerReport {
  status: any;
  data: any;
  search: any;
  opening: number;
  actual_opening: number;
   totalArr:any;
   ledData:any;
}

@Injectable({
  providedIn: 'root'
})
export class AccountReportService {
  headersdata:any={};
  authValue:any={};
  user_id:string;
  login_token:string;
  login_year:string;

constructor(private http: HttpClient, private session:SessionStorageService, private local_storage:LocalStorageService) { 

this.authValue = this.local_storage.get('localAuthData');

//console.log(this.authValue);
  if(this.authValue){
    this.user_id=this.authValue.user_id;
    this.login_token=this.authValue.token;
    this.login_year=this.authValue.financial_year_id;
    console.log(this.login_year);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('user', this.user_id);
    headers = headers.append('year', this.login_year);
    this.headersdata = headers.append('token', this.login_token);
  }
}
  
  
  
    /*
  Get Ledger Reprt Data
  22.12.18
  Amit Sahu  
  */
  getLedgerReportData(petro_id:number,ledgerReportSearch:any){
	return this.http.post<serviceDataLedgerReport>(constDefault.API_URL+'/accounts/ledgerReport/'+petro_id+'/'+this.login_year ,ledgerReportSearch,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
  
   /*
  Get Ledger Reprt Load More Data
  22.12.18
  Amit Sahu  
  */
  getLedgerReportLoadMoreData(petro_id:number,last_id:number,ledgerReportSearch:any){
	return this.http.post<serviceDataLedgerReport>(constDefault.API_URL+'/accounts/loadMoreLedgerReport/'+petro_id+'/'+last_id+'/'+this.login_year ,ledgerReportSearch,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
   /*
  Get Trail Balance Data
  22.12.18
  Amit Sahu  
  */

  getTrialBalanceData(petro_id:number,searchData:any){
	return this.http.post<serviceDataLedgerReport>(constDefault.API_URL+'/accounts/trialBalance/'+petro_id+'/'+this.login_year,searchData,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
   /*
  Get Trail Balance details Data
  22.12.18
  Amit Sahu  
  */

  getTrialBalanceDetails(petro_id:number,group_id:number,searchData:any){
	return this.http.post<serviceDataLedgerReport>(constDefault.API_URL+'/accounts/trialBalanceDetails/'+petro_id+'/'+group_id+'/'+this.login_year ,searchData,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
  
  /*
  Get Ledger Wise Trail Balance Data
  06.02.18
  Amit Sahu  
  */

  getLedgerTrialBalanceData(petro_id:number,group_id:number,searchData:any){
	return this.http.post<serviceDataLedgerReport>(constDefault.API_URL+'/accounts/getLedgerTrialBalanceData/'+petro_id+'/'+group_id+'/'+this.login_year ,searchData,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
  
  /*
  Get Account bill to bill ledger data
  07.02.18
  Amit Sahu  
  */

  getCustomerBillToBIllLedgerData(petro_id:number,searchData:any){
	return this.http.post<serviceDataLedgerReport>(constDefault.API_URL+'/accounts/getCreditBillData/'+petro_id+'/'+this.login_year,searchData,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
  
    /*
  Get Bill to Bill Ledger Reprt Load More Data
  07.02.19
  Amit Sahu  
  */
  getBillToLedgerReportLoadMoreData(petro_id:number,last_id:number,searchData:any){
	return this.http.post<serviceDataLedgerReport>(constDefault.API_URL+'/accounts/loadMoreCreditBillData/'+petro_id+'/'+last_id+'/'+this.login_year,searchData,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
  
   /*
  Get Account bill to bill outstanding data
  23.04.18 
  Amit Sahu  
  */

  getCustomerBillToBIllOutrstandingData(petro_id:number){
	return this.http.get<serviceDataLedgerReport>(constDefault.API_URL+'/reports/outstandingReportBBWise/'+petro_id +'/'+this.login_year,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
   /*
  Get Account outstanding data
  23.04.18 
  Amit Sahu  
  */

  getCustomerOutrstandingData(petro_id:number,data:any){
	return this.http.post<serviceDataLedgerReport>(constDefault.API_URL+'/reports/customerOutstandingReport/'+petro_id+'/'+this.login_year,data ,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }


  getCustomerOutrstandingCostData(petro_id:number,data:any){
    return this.http.post<serviceDataLedgerReport>(constDefault.API_URL+'/reports/customerOutstandingCostReport/'+petro_id+'/'+this.login_year,data ,{headers:this.headersdata})
    //.subscribe(data=>{console.log('ABC',data)})categoryPageList
  
    }
  getCustomerAgeingData(petro_id:number,data:any){
    return this.http.post<serviceDataLedgerReport>(constDefault.API_URL+'/reports/customerAgeingReport/'+petro_id+'/'+this.login_year,data ,{headers:this.headersdata})
    //.subscribe(data=>{console.log('ABC',data)})categoryPageList
  
    }
  
     /*
  Get Profit and loss data
  23.04.18 
  Amit Sahu  
  */

  getPropfitAndLossData(petro_id:number,searchData:any){
	return this.http.post<serviceDataLedgerReport>(constDefault.API_URL+'/reports/profitAndLossReport/'+petro_id+'/'+this.login_year,searchData,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
    /*
  Get Balance Sheet data
  23.04.18 
  Amit Sahu  
  */

  getBalancesheetData(petro_id:number,searchData:any){
	return this.http.post<serviceDataLedgerReport>(constDefault.API_URL+'/reports/balanceSheetsReport/'+petro_id+'/'+this.login_year,searchData,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
  
  

}
