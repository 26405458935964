import { Component, OnInit, Input } from '@angular/core';
import { MasterServicesService } from '../../admin-services/master-services.service';
import { CommonsService } from '../../admin-services/commons.service';
import { FormGroup,FormControl,Validators ,FormBuilder} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Router} from '@angular/router';
import { uniqueByModalOrNameValidation } from '../../shared/unique-validation.directive';
import { constDefault } from '../../const_default';
declare var $: any;

@Component({
  selector: 'app-customer-center',
  templateUrl: './customer-center.component.html',
  styleUrls: ['./customer-center.component.css']
})
export class CustomerCenterComponent implements OnInit {

 

  constructor(private masterService: MasterServicesService, private commonService:CommonsService, private router:Router,private session:SessionStorageService, private commonsService:CommonsService, private formBuilder: FormBuilder ) { }
	

	authData:any={};
	addTankData:any={};	
	editTankData:any={};	
	addCallibrationData:any={};	
	serachTankData:any={};	
	tankList:any[]=[];	
	calibrationList:any[]=[];	
	CommodityDropdownList:any[]=[];
	uploadFilesData:File=null;	
	success_message:string;	
	error_message:string;	
	currentPage:number;	
	petro_id:number;	
	index:number;	
	totalData:number;	
	requestPending:boolean;	
	add_loading:boolean;	
	
	link_path:string;
	delete_btn:number;
	edit_btn:number;
	create_btn:number;
	btnPrevSession:any[]=[];
	
	succeAddData:{
		Tank:{
			id:number,						
			main_ledger:number,
			sub_ledger:number,
		}
	};
	
	
	
		//UniqueValidation()
	addTankForm:FormGroup;
	editTankForm= new FormGroup({
		id : new FormControl(' ',Validators.required),
		main_ledger : new FormControl(' ',Validators.required),
		sub_ledger : new FormControl(' ',Validators.required),
			
	});	
	searchTankForm= new FormGroup({
		name : new FormControl(' ',Validators.required),
	});	
	
	addTankCallibrationForm= new FormGroup({
    	id : new FormControl(' ',Validators.required),
		file : new FormControl(null,Validators.required)
	});	

	// for ng-select
  selectedPeople1 = [];
	selectedPeople2 = [];
	
  ngOnInit() {
	 // Button Privilages
		this.btnPrevSession = this.session.get('ButtonAuth');
		if(this.btnPrevSession)
		{
			let urlArr=this.router.url.split('/');
			this.link_path=urlArr[2];
			let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
			this.delete_btn=result.UserPrivilege.delete_auth;
			this.edit_btn=result.UserPrivilege.edit_auth;
			this.create_btn=result.UserPrivilege.create_auth;
		}
		// End Button Privilages
		this.addTankData.main_ledger="";
    this.addTankData.sub_ledger="";
		this.requestPending=false;
		this.authData = this.session.get('authData');
		this.petro_id=this.authData.petro_id;
		
		this.addTankForm= this.formBuilder.group({
		
			main_ledger : new FormControl(' ',Validators.required),
			sub_ledger : new FormControl(' ',Validators.required)
		});	

		this.pageWiseTankList(1);		
		this.commonsService.getLedgerListByGroup(constDefault.SUNDRY_DEBTOR_GROUP, this.petro_id)
		.subscribe(commoditylistdata =>
		{			//console.log(commoditylistdata);
			this.selectedPeople1= Object.entries(commoditylistdata.data).map(([value, label]) => ({value,label}));			
      this.selectedPeople2= Object.entries(commoditylistdata.data).map(([value, label]) => ({value,label}));			
		});
		
	}
	
  
 get name(){
	  return this.addTankForm.get('name');
  }

/*
  Get Data for Tank list show
  Amit Shau
	20.11.18
*/
    pageWiseTankList(pageno){
	   this.currentPage=pageno;
	   this.masterService.getCostListData(pageno,this.petro_id,this.serachTankData)
		.subscribe(alldata =>{
			if(alldata.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}
			this.tankList=alldata.data,
			this.totalData=alldata.count
			 
	 	});
   	}
 
/*
  Add Tank 
  Amit Sahu
  20.11.18
*/
   addTank(formData: any,add_tank)
  {
	  this.add_loading=true;
	  	add_tank.submitted = false;
		this.authData = this.session.get('authData');
		this.addTankData.petro_id=this.authData.petro_id;	
		this.addTankData.main_ledger=this.addTankData.main_ledger.value;
    this.addTankData.sub_ledger=this.addTankData.sub_ledger.value;
		this.masterService.addNewCost(this.addTankData).subscribe(data=>
		{
			if(data.status==200)
			{
				this.success_message='Entry has been created';
				$('#addTankModal').modal('hide');
				this.pageWiseTankList(1);
				this.addTankForm.reset(); 
				
				
			}else if(data.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
				this.error_message='Item category could not be created';
			}
			this.add_loading=false;
			this.addTankForm.reset(); 
      formData.reset();
		});
		
  }

   /*
  Amit Shau
  Get Customer Data For Edit;
  20.11.18
  */ 
  getTankData(id,index)
  {
		this.index=index;
		this.masterService.getEidtCostData(id).subscribe(data=>{
			this.editTankData.id=data.data.CostCenter.id;						
			this.editTankData.main_ledger=data.data.CostCenter.main_ledger;						
								
			this.editTankData.sub_ledger=data.data.CostCenter.sub_ledger;						
      this.commonsService.getLedgerListByGroup(constDefault.SUNDRY_DEBTOR_GROUP, this.petro_id)
				.subscribe(commoditylistdata =>{
					if(commoditylistdata.status==403)
					{
						  this.session.remove('authData');
							this.router.navigate(['']);
					}else{
					this.CommodityDropdownList=commoditylistdata.data
					}
					});
			$('#editTankModal').modal('show');
		});	
  } 

/*
  Amit Sahu
  Edit Tank
	20.11.18
*/  
  editTank(edit_tank)
  {
		edit_tank.submitted=false;
		this.masterService.editCost(this.editTankData ).subscribe(data=>
		{
			if(data.status==200)
			{			
				this.success_message='Mapping has been updated';
				$('#editTankModal').modal('hide');
				this.pageWiseTankList(1);
				//this.tankList[this.index]=this.succeAddData;
				//console.log(this.categoryList);
				this.editTankForm.reset(); 
			}else if(data.status==403)
					{
						  this.session.remove('authData');
							this.router.navigate(['']);
					}else{
				this.error_message='Mapping could not be updated';
			}
		});
	}
	

/*
  Delete Item 
  Amit Sahu
	21.11.18
*/
  deleteTank(id,index)
  {
		if(confirm("Are you sure to delete this Mapping ")){ 
			this.index=index;
			this.masterService.deleteDataByModel(id,'CostCenter').subscribe(data=>{
				if(data.status==true)
				{
				this.tankList.splice(this.index,1);
				this.success_message="Mapping deleted sucessfully";		
				
				}else{
					this.error_message="Mapping could not be deleted.";
				}
			});
		}
  }

/*
  Amit Sahu
  Search Customer-master
  19.11.18
*/
  searchTank()
  {
	 	this.pageWiseTankList(1);
  }
  
  resetSearchTank(){
	  this.serachTankData={};
		this.pageWiseTankList(1);
  }
  
/*
	Amit Sahu
	Open Add Tank Callibration 
	21.11.18
*/

  openAddCallibrationModal(id){
	  this.addCallibrationData.id=id;	  
	  this.masterService.getTankCalibration(id)
		.subscribe(alldata =>{
			 if(alldata.status==403)
					{
						  this.session.remove('authData');
							this.router.navigate(['']);
					}else{
			this.calibrationList=alldata.data
					}
		});
	  $('#addTankCallibrationModal').modal('show');
  }
	
	
/*
  Upload Image
  Amit Shau
  21.11.18
*/
  onFileChange(event) {
		this.uploadFilesData=<File>event.target.files[0];
	}


/*
  Amit Sahu
  Add Tank Callibration
	21.11.18
*/  
  addCallibration()
  {

		this.authData = this.session.get('authData');
		const duploadData=new FormData();
		duploadData.append('image',this.uploadFilesData);
		duploadData.append('tank_id',this.addCallibrationData.id);
		duploadData.append('petro_id', this.authData.petro_id);
		if(this.uploadFilesData.type=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
		{
			this.requestPending=true;
			this.addCallibrationData.file=this.uploadFilesData;
			this.masterService.addTankCalibration(duploadData).subscribe(data=>
			{
				if(data.status==200)
				{
					this.success_message='Tank Calibration has been updated';
					$('#addTankCallibrationModal').modal('hide');
				}else if(data.status==403)
					{
						  this.session.remove('authData');
							this.router.navigate(['']);
					}else{
					this.error_message='Tank Calibration could not be updated';
				}
				this.requestPending=false;
		    	});
	    	}else{
	 			this.error_message='Please select a excel file';
		}	
  }  
}
