import { Component, OnInit } from '@angular/core';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { FormGroup,FormControl,Validators ,FormBuilder} from '@angular/forms';
import { CommonsService } from '../../../admin-services/commons.service';
import { DatepickerOptions } from 'ng2-datepicker';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import * as enLocale from 'date-fns/locale/en';
import{DatePipe}from '@angular/common';
import { SaleServiceService } from '../../../admin-services/sale-service.service';
// Custoem Function
import { OnCreatedDirective } from '../../../shared/on-created.directive';
import { Router} from '@angular/router';

@Component({
  selector: 'app-dsm-sum',
  templateUrl: './dsm-sum.component.html',
  styleUrls: ['./dsm-sum.component.css']
})
export class DsmSumComponent implements OnInit {

  total_sale_other:number;
  total_sale_ms:number;
  total_sale_hsd:number;
 
  total_cash_other:number;
  total_cash_ms:number;
  total_cash_hsd:number;
 
  total_credit_other:number;
  total_credit_ms:number;
  total_credit_hsd:number;
  
  ms_hsd_total:number;
  credit_total:number;
   other_sale_total:number;
   bank_total:number;
   sale_total:number;
   cash_sale_total:number;
   credit_sale_total:number;
   receipt_total:number;
   expenses_total:number;
   row1:number;
   mshsd_cash_total:number;
   mshsd_credit_total:number;
    ms_hsd_sale:number;
  ms_hsd_cash_sale:number;
  ms_hsd_credit_sale:number;
  ms_hsd_sale_total:number;
  
   setDob:string;
   setDoe:string;
   totalamount:number;
   amount:number;
   authData:any={};
   petro_id:number;
   day_shift:number;
   pump_name:string;
   pump_address:string;
   msg:string;
   shift_no:number;
    receiptData:any=[];
 expensesData:any=[];
   shift_detail:any[]=[];
   row_total_array:any={};
   config:any;
  amount_data:number;
   ledger_is_debit:number;
   credit:any[]=[];
   bank:any[]=[];
   mshsddetails:any[]=[];
	ledgerAutoSugestList: any[] = [];
   receipt:any[]=[];
   othersale:any[]=[];
   DailyShift:any[]=[];
   shiftsummary:any[]=[];
   reportData: any[] = [];
   dipReading: any[] = [];
  show_details:boolean;
   serachShiftReportData:any={};	
   merged:any[]=[];
   merged1:string;
  constructor(private router:Router,private exportAsService:ExportAsService, private session:SessionStorageService,private saleServiceService:SaleServiceService,private commonsServices:CommonsService,) { }
  exportAsConfig: ExportAsConfig;
  
  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'YYYY-MM-DD',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		//locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
   
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select  Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };

	searchShiftReportForm= new FormGroup({
		from_date : new FormControl(' ',Validators.required),
		to_date : new FormControl(' ',Validators.required),
		tmp_ledger_id : new FormControl(' ')
		
  });	

  ngOnInit() {
    this.authData = this.session.get('authData');
    this.petro_id = this.authData.petro_id;
    this.serachShiftReportData.from_date=new Date();
    this.serachShiftReportData.to_date=new Date();
    this.pump_name=this.authData.petro_name;	
	 	this.pump_address=this.authData.petro_address;
     this.filterItemList();
  }
 public filterItemList():void {	  
 this.ledgerAutoSugestList=[];
 this.reportData=[];
  this.shiftsummary=[];
  this.amount=0;
 this.day_shift= this.serachShiftReportData.shift_no;
	  var datePipe =  new DatePipe('en-US');
    this.setDob = datePipe.transform(this.serachShiftReportData.from_date, 'yyyy-MM-dd');
    this.setDoe = datePipe.transform(this.serachShiftReportData.to_date, 'yyyy-MM-dd');
 //console.log(this.setDob);
	   this.commonsServices.getDsmByNameDate(this.petro_id, this.setDob,this.setDoe)
		.subscribe(alldata => {
		this.ledgerAutoSugestList=Object.entries(alldata.data).map(([value, label]) => ({value,label}));
		});
	 
 

  }

  resetSearchShiftReport(){
	  
  this.serachShiftReportData.from_date=new Date();
  this.serachShiftReportData.to_date=new Date();
  this.serachShiftReportData.shift_no='';
  this.serachShiftReportData.ledger_id='';
  this.reportData=[];
  this.shiftsummary=[];
  this.amount=0;


}


  searchShiftReport(){
      this.serachShiftReportData.ledger_id='';
    var datePipe =  new DatePipe('en-US');
    this.setDob = datePipe.transform(this.serachShiftReportData.from_date, 'yyyy-MM-dd');
    this.setDoe = datePipe.transform(this.serachShiftReportData.to_date, 'yyyy-MM-dd');
	if(this.serachShiftReportData.tmp_ledger_id)
			{
			this.serachShiftReportData.ledger_id=this.serachShiftReportData.tmp_ledger_id.value;
			}
    //console.log(this.setDob);
    this.saleServiceService.getDSMReportDataDate(this.setDob,this.setDoe,this.petro_id,this.serachShiftReportData.ledger_id)
   
  .subscribe(purchaseListData => {
  //  console.log(purchaseListData);
   if(purchaseListData.status==200)
    {
		this.msg="";
   this.reportData=purchaseListData.data;
   this.shiftsummary=purchaseListData.shiftSummary;
   this.amount=purchaseListData.amount;
 
}else if(purchaseListData.status==403)
{
    this.session.remove('authData');
    this.router.navigate(['']);
} else if(purchaseListData.status==205){
      this.msg="Please insert valid shift";
    }else if(purchaseListData.status==204){
      this.msg="DSM not Assigned !!";
    }else{
		this.msg="Bad Request !!";
	}
  });
  }



	showDetails(event){
		if(event.target.checked){
			this.show_details=true;
		}else{
			this.show_details=false;
		}
	}




  exportTable(event) {
    if(event.target.value=='xlsx')
    {
    this.exportAsConfig = {
      type: event.target.value, // the type you want to download
      elementId: 'exportTable', // the id of html/table element
    }	
      //this.exportTableDirective.exportData();
      // download the file using old school javascript method
      this.exportAsService.save(this.exportAsConfig, 'Shift Report ');
      // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
      this.exportAsService.get(this.config).subscribe(content => {
      
      });
    }
  }
  	printReport() {
		var printContents = document.getElementById("exportTable").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}
   

}

