import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { constDefault } from '../const_default';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

interface getData{
  data:any,
  status:any
}
interface getConverIndentData{
  data:any,
  status:any,
  credit_amt:any,
  credit_limit:any,
}

interface getDataForInvoice{
  data:any,
  ao_charge:any,
  ao_per:any,
invoice_no:any,
inv_prefix:any,
  status:number
}

interface deleteData{
	status:number,
	id:number,
}

interface serviceDataSale {
  status: number;
  data: any;
  count: number;
}

interface serviceDataCardSale {
  status: number;
  data: any;
  cardSaleSummary: any;
  count: number;
}

interface PGSale {
  status: number;
  data: any;
  cardSaleSummary: any;
  count: number;
}
interface shiftInfo {
  status: number;
  shift: number;
}
interface shiftSaleSummary {
  status: number;
  summary_data: any;
  graph_data: any;
 
}
interface addData{
	data:any,
	status:number,
  message:string,
} 



@Injectable({
  providedIn: 'root'
})

export class ShiftSaleService {
	headersdata:any={};
		authValue:any={};
		user_id:string;
		login_token:string;
		login_year:string;
   constructor(private http: HttpClient,private session:SessionStorageService, private local_storage:LocalStorageService) { 
     
   this.authValue = this.local_storage.get('localAuthData');
   if(this.authValue)
   {
   //console.log(this.authValue);
   this.user_id=this.authValue.user_id;
   this.login_token=this.authValue.token;
   this.login_year=this.authValue.financial_year_id;
    //console.log(this.login_year);
   let headers: HttpHeaders = new HttpHeaders();
   headers = headers.append('Accept', 'application/json');
   headers = headers.append('user', this.user_id);
   headers = headers.append('year', this.login_year);
   this.headersdata = headers.append('token', this.login_token);
   }
	}
   
   
    /*
  Get Shift Wise Nozzle Reading 
  11.12.18
  Amit Sahu  
  */
  getShifyWiseZozzleData(day_shift:number,shift:number,date:any,petro_id:number){
	return this.http.get<getData>(constDefault.API_URL+"/petros/getNozzleListByShiftNo/"+day_shift+"/"+shift+'/'+date+'/'+petro_id+'/'+this.login_year,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
  
  /*
  Calculate Sale 
  11.12.18
  Amit Sahu  
  */
  getCalculateSale(data:any){
	return this.http.post<getData>(constDefault.API_URL+"/petros/getTotalSaleByShift/"+this.login_year,data,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
  /*
  Calculate Sale 
  11.12.18
  Amit Sahu  
  */
  shiftWiseNozzleDateentry(data:any){
	return this.http.post<getData>(constDefault.API_URL+"/petros/addShiftWiseNozzleEntry/"+this.login_year,data,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
  /*
  Get Item Data By Id For Sale 
  15.12.18
  Amit Sahu  
  */
  getItemDetailsByItemIdForSale(item_id:any,shift_no:number){
	return this.http.get<getData>(constDefault.API_URL+"/petros/getItemDetailsByForSale/"+item_id+'/'+shift_no+'/'+this.login_year,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
  /*
  Get Item Data By Id For Sale 
  15.12.18
  Amit Sahu  
  */
  getItemStockByIdAndBatchNo(item_id:number,batch_no:string,petro_id:number){
	return this.http.get<getData>(constDefault.API_URL+"/commons/itemwiseStock/"+item_id+'/'+batch_no+'/'+petro_id+'/'+this.login_year,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
  
   /*
  Add Sale
  18.12.18
  Amit Sahu  
  */
  addSale(data:any){
	return this.http.post<getConverIndentData>(constDefault.API_URL+"/petros/addSale/"+this.login_year,data,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }

  /*
  Add Miscllenious
  30.01.2019
  Amit Sahu  
  */
  addMiscllenious(data:any){
    return this.http.post<getData>(constDefault.API_URL+"/petros/addMisclleniousCust/"+this.login_year,data,{headers:this.headersdata})
  }

  /*
  Get Miscllenious Customer list
  30.01.2019
  Amit Sahu   
  */
 getMisclleniousCustList(petro_id:number){
  return this.http.get<getData>(constDefault.API_URL+"/petros/misclleniousCustList/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
}

getMisclleniousCustListbyCust(cust_id:number,p_id:number){
  return this.http.get<getData>(constDefault.API_URL+"/petros/misclleniousCustListByCust/"+cust_id+'/'+p_id+'/'+this.login_year,{headers:this.headersdata})
}
   /*
  Get Sale list by shift or type
  15.12.18
  Amit Sahu  
  */
  getSaleListByTypeOrShift(pages:number,shift:number,sale_type:number,petro_id:number){
    
    return this.http.get<serviceDataSale>(constDefault.API_URL+'/petros/getSaleListByShiftOrType/page:'+pages+'/'+shift+'/'+sale_type+'/'+petro_id+'/'+this.login_year,{headers:this.headersdata})
    //.subscribe(data=>{console.log('ABC',data)})categoryPageList
  }
    /*
  Get Sale data by sale id
  19.12.18
  Amit Sahu  
  */
  getSaledataById(sale_id:number){
	return this.http.get<getData>(constDefault.API_URL+'/petros/getSaleDataById/'+sale_id+'/'+this.login_year,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }


  /*
  Get Card Data List
  19.12.18
  Roshan Bagde
  */
 getCardListByShift(pages:number,shift:number,petro_id:number,card_type:number){
  
  return this.http.get<serviceDataCardSale>(constDefault.API_URL+'/petros/cardSaleList/page:'+pages+'/'+shift+'/'+petro_id+'/'+card_type+'/'+this.login_year,{headers:this.headersdata})
 }

 /*
  Add Card Data
  19.12.18
  Roshan Bagde
  */
  addCardData(data:any){
    return this.http.post<getData>(constDefault.API_URL+"/petros/addCardSale/"+this.login_year, data,{headers:this.headersdata});
  }

 convertCardData(data:any){
  
    return this.http.post<getData>(constDefault.API_URL+"/petros/convertCardSale/"+this.login_year, data,{headers:this.headersdata});
  }


 /*
  Card Settlement
  14.03.19
  Roshan Bagde
  */
  cardSettlement(data:any){
    return this.http.post<getData>(constDefault.API_URL+"/petros/settleMSwipeSale/"+this.login_year, data,{headers:this.headersdata});
  }

    /*
  Get Pending Indent 
  19.12.18
  Amit Sahu  
  */
  autoloadPeningIntendList(petro_id:number){
	return this.http.get<getData>(constDefault.API_URL+'/petros/autoloadPendingIndentList/'+petro_id+'/'+this.login_year,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }

  autoloadPeningPhonepeList(petro_id:number,sDate:string ){
    return this.http.get<PGSale>(constDefault.API_URL+'/response/autoloadPendingIndentList/'+petro_id+'/'+sDate+'/'+this.login_year,{headers:this.headersdata})
    //.subscribe(data=>{console.log('ABC',data)})categoryPageList
  
    }
	
 /*
 Convert Indent To Credit Sale
  18.12.18
  Amit Sahu  
  */
  converIdentToCreditSale(data:any){	
    return this.http.post<getConverIndentData>(constDefault.API_URL+"/petros/convertIndentToCreditSale/"+this.login_year, data,{headers:this.headersdata})
  }	

  
  /* 
  Get Price By Current shift
  09.03.19
  Roshan Bagde  
  */
  getPriceByShift(item_id:number,shift_id:number,petro_id:number){	
    return this.http.get<getData>(constDefault.API_URL+"/petros/getPriceByShiftNoAndItem/"+item_id+"/"+shift_id+"/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
  }	
  /*
  Edit Indent Sale
  29.1.19
  Roshan Bagde  
  */
  editIndents(data:any){	
    return this.http.post<getData>(constDefault.API_URL+"/petros/editIndent/"+this.login_year, data,{headers:this.headersdata})
  }	

  addBozerTransfer(data:any){	
    return this.http.post<getData>(constDefault.API_URL+"/petros/addBozerTransfer/"+this.user_id+"/"+this.login_year, data,{headers:this.headersdata})
  }	

  editBozerTransfer(data:any){	
    return this.http.post<getData>(constDefault.API_URL+"/petros/editBozerTransfer/"+this.user_id+"/"+this.login_year, data,{headers:this.headersdata})
  }	

  addBozer(data:any){	
    return this.http.post<getData>(constDefault.API_URL+"/petros/addBozer/"+this.login_year, data,{headers:this.headersdata})
  }	
  
  /*
  delete shift Sale Transiction (Sale) 
  29.1.19
  Roshan Bagde  
  */
  deleteSale(sale_id:number){	
    return this.http.get<deleteData>(constDefault.API_URL+"/petros/deleteSale/"+sale_id+'/'+this.login_year,{headers:this.headersdata})
  }	


  /*
 Shift Info
  03.1.19
  Amit Sahu  
  */
  shiftValidationOrInfo(data:any){	
    return this.http.post<shiftInfo>(constDefault.API_URL+"/petros/getShiftInfo/"+this.login_year,data,{headers:this.headersdata})
  }	
  
    /*
 Close Shift
  03.1.19
  Amit Sahu  
  */
  closeShift(data:any){	
    return this.http.post<getData>(constDefault.API_URL+"/petros/closeShift/"+this.login_year,data,{headers:this.headersdata})
  }	
  
  /*
 Get Current Shift Data
  08.1.19
  Amit Sahu  
  */
  getCurrentShiftData(petro_id:number){	
    return this.http.get<getData>(constDefault.API_URL+"/petros/getCurrentShiftData/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
  }	

  /*==================================== Summary Details ==================================== */
  /*
  Get Sale in Liters
  22.12.18
  Roshan Bagde  
  */
  saleInLiters(shift:number,petro_id:number){
    return this.http.get<getData>(constDefault.API_URL+'/petros/saleSummaryDashboard/'+shift+'/'+petro_id+'/'+this.login_year,{headers:this.headersdata});
  }
  /*
Get Sale in Liters
  22.12.18
  Roshan Bagde  
  */
  getShiftSaleSummarydata(shift:number,petro_id:number,date:any,day_shift_no:number){
    return this.http.get<shiftSaleSummary>(constDefault.API_URL+'/petros/saleSummaryDashboard/'+shift+'/'+petro_id+'/'+date+'/'+day_shift_no+'/'+this.login_year,{headers:this.headersdata});
  }
  /*
  Get Sale in Liters
  22.12.18
  Roshan Bagde  
  */
  assignDsmData(data:any){
    return this.http.post<getData>(constDefault.API_URL+"/petros/addDsmData/"+this.login_year,data,{headers:this.headersdata})
  }

  /*
  Get Dsm Data
  22.12.18
  Roshan Bagde  
  */
  getDsmData(petro_id:number){ 
    return this.http.get<getData>(constDefault.API_URL+'/petros/getDsmList/'+petro_id+'/'+this.login_year,{headers:this.headersdata});
  }

  /*==================================== Receipt Voucher  ==================================== */
  /*
    Add Shift Receipt Voucher Data
    20.01.19
    Roshan Bagde
  */
   addShiftReceiptVoucher(addData){
    return this.http.post<getData>(constDefault.API_URL+"/petros/addShiftReceiptVoucher/"+this.login_year,addData,{headers:this.headersdata});
  }


   /*
    Get Shift Receipt Card Voucher info By id
    21.01.19
    Roshan Bagde
  */
 getReceiptCardVoucherById(voucher_id:number){
  return this.http.get<getData>(constDefault.API_URL+"/petros/viewReceiptCardVoucher/"+voucher_id+'/'+this.login_year,{headers:this.headersdata});
 }

 getBozerTransferById(voucher_id:number){
  return this.http.get<getData>(constDefault.API_URL+"/petros/viewBozerTransfer/"+voucher_id+'/'+this.login_year,{headers:this.headersdata});
 }

 /*
    Get Receipt Voucher list By shift_id
    21.01.19
    Roshan Bagde
  */
  receiptCardVoucherList(pageno:number,shift:any,day_shift:any,petro_id:number){
    return this.http.get<serviceDataCardSale>(constDefault.API_URL+'/petros/receiptCardVoucherList/page:'+pageno+'/'+shift+'/'+day_shift+'/'+petro_id+'/'+this.login_year,{headers:this.headersdata})
  }

  bozerTransferList(pageno:number,shift:any,day_shift:any,petro_id:number){
    return this.http.get<serviceDataCardSale>(constDefault.API_URL+'/petros/bozerTransferList/page:'+pageno+'/'+shift+'/'+day_shift+'/'+petro_id+'/'+this.login_year,{headers:this.headersdata})
  }

  /*
    Get Receipt Voucher list By shift_id
    22.01.19
    Roshan Bagde
  */
 getBankDepositeVoucherList(pageno:number,shift:any,day_shift:any,petro_id:number){
  return this.http.get<serviceDataCardSale>(constDefault.API_URL+'/petros/contraBankDepositVoucherList/page:'+pageno+'/'+shift+'/'+day_shift+'/'+petro_id+'/'+this.login_year,{headers:this.headersdata})
 }

  /*
    Get Receipt Voucher list By shift_id
    22.01.19
    Roshan Bagde
  */
 getPaymentExpensesVoucherList(pageno:number,shift:any,day_shift:any,petro_id:number){
  return this.http.get<serviceDataCardSale>(constDefault.API_URL+'/petros/paymentExpensesVoucherList/page:'+pageno+'/'+shift+'/'+day_shift+'/'+petro_id+'/'+this.login_year,{headers:this.headersdata})
}
  
 /*
    Edit and Submit Receipt Voucher Data 
    09.01.19
    Roshan Bagde
  */
 editReceiptVoucher(addData){
  return this.http.post<getData>(constDefault.API_URL+"/petros/editReceiptCardVoucher/"+this.login_year,addData,{headers:this.headersdata});
}

/*
    Get sales data for generate invoice 
    24.01.19
    Amit Sahu
  */
 getSalesDataForCreateInvoice(searchData){
  return this.http.post<getDataForInvoice>(constDefault.API_URL+"/petros/getCreditSaleItemFoRInvoiceGenerate/"+this.login_year,searchData,{headers:this.headersdata});
}
/*
amit Sahu
generate invoice
24.1.19*/
 generateCreditInvoice(data){
  return this.http.post<getData>(constDefault.API_URL+"/petros/generateCreditInvoice/"+this.login_year,data,{headers:this.headersdata});
}

/*
amit Sahu
Get Invoice list
25.1.19*/

getInvoiceListData(pages:number, petro_id:number, searchData:any){
	return this.http.post<serviceDataSale>(constDefault.API_URL+'/petros/creditInvoiceList/page:'+pages+'/'+petro_id+'/'+this.login_year, searchData,  {headers:this.headersdata})

  }
/*
Amit Sahu
Get Invoice data by id
25.1.19
*/
getInvoiceDataById(invoice_id:number){
  return this.http.get<getData>(constDefault.API_URL+"/petros/getInvoiceDataById/"+invoice_id+'/'+this.login_year,{headers:this.headersdata});
}

/*
Shift sale transaction
Roshan Bagde
Get VehicleList by cust_id
25.1.19
*/
 getVehicleListByCustId(cust_id:number,petro_id:number){
  return this.http.get<getData>(constDefault.API_URL+"/petros/getVehicleListByCustId/"+cust_id+"/"+petro_id+'/'+this.login_year,{headers:this.headersdata});
 }

/*
Shift sale transaction
Roshan Bagde
Get Vehicle Info by cust_id
25.1.19
*/
 getVehicleSaleInfoByCustId(cust_id:number,vehicle_no:number,petro_id:number){
  return this.http.get<getData>(constDefault.API_URL+"/petros/getSaleByVehicleNo/"+cust_id+"/"+vehicle_no+"/"+petro_id+'/'+this.login_year,{headers:this.headersdata});
 }

  /*
  Get Shift Wise Dip Reading Data 
  15.02.19
  Roshan Bagde  
  */
  getShiftWiseDipReadingData(day_shift:number,shift:number,date:any,petro_id:number){
	  return this.http.get<getData>(constDefault.API_URL+"/petros/dipReadingList/"+day_shift+"/"+shift+'/'+date+'/'+petro_id+'/'+this.login_year,{headers:this.headersdata})
  }
    /*
    Add Payment Voucher Data
    04.01.19
    Roshan Bagde 
    */
  addPaymentVoucher(addData){
    return this.http.post<addData>(constDefault.API_URL+"/petros/addPaymentVoucher/"+this.login_year,addData,{headers:this.headersdata});
  }
 /*
    Edit and Submit Payment Voucher Info
    04.01.19
    Roshan Bagde
  */ 
  editPaymentVoucher(addData){
    return this.http.post<addData>(constDefault.API_URL+"/petros/editPaymentVoucher/"+this.login_year,addData,{headers:this.headersdata});
  }
 
  /*
   Get Shift Wise Dip Reading Data by dip Entry
   16.02.19
   Roshan Bagde  
  */
  getDipByLength(op_dip_value:number,tank_id:number,petro_id:number){
	  return this.http.get<getData>(constDefault.API_URL+"/petros/getDipReadingByLength/"+this.login_year+'/'+op_dip_value+"/"+tank_id+'/'+petro_id,{headers:this.headersdata})
  }

  /*
  Add Shift wise Dip reading 
  18.02.19
  Roshan Bagde  
  */
 addshiftWiseDipEntry(data:any){
	return this.http.post<getData>(constDefault.API_URL+"/petros/addShiftWiseDipEntry/"+this.login_year,data,{headers:this.headersdata})
  }
  
   /*
   Get Shift Wise Dip Reading Data by litre (Stock)
   16.02.19
   Roshan Bagde  
  */
  getDipByLitre(op_dip_value:number,tank_id:number,petro_id:number){
    return this.http.get<getData>(constDefault.API_URL+"/petros/getDipReadingByLitre/"+this.login_year+'/'+op_dip_value+"/"+tank_id+'/'+petro_id,{headers:this.headersdata})
  }
  
  /*
  Amit Sahu
  Delete shft received voucher_id
23.03.2019*/

   
 deleteShiftReceivedVoucher(id){
  //configUrl="http://localhost/society/mobile/login";
  return this.http.get<deleteData>(constDefault.API_URL+"/petros/receiptVoucherDelete/"+id+'/'+this.login_year,{headers:this.headersdata})
  //.subscribe(data=>{console.log('ABC',data)})
}  


  /*
   Get shift list
   28.03.19
   Amit Sahu
  */
  getShifyList(petro_id:number){
    return this.http.get<getData>(constDefault.API_URL+"/petros/getallShiftList/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
  }
    /*
   Get previous for generate invoice
   28.03.19
   Amit Sahu
  */
  getPreviosBalanceForInvoices(petro_id:number,searchData:any){
    return this.http.post<getData>(constDefault.API_URL+"/petros/getPrevBalanceForGenrateInvoice/"+petro_id+'/'+this.login_year,searchData,{headers:this.headersdata})
  }
      /*
   Nozzle Assigned list
   28.03.19
   Amit Sahu
  */
  getShiftNozzleAssignedList(petro_id:number,shift:number){
    return this.http.get<getData>(constDefault.API_URL+"/petros/getAssignedNozzleDsmList/"+petro_id+'/'+shift+'/'+this.login_year,{headers:this.headersdata})
  }
  
   /*
  Add Sale
  18.12.18
  Amit Sahu  
  */
  addStaockTransfer(data:any){
	return this.http.post<getData>(constDefault.API_URL+"/petros/addStockTransfer/"+this.login_year,data,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})categoryPageList

  }
   /*
  Get Stock Transfer Details
 13.05.2019
  Amit Sahu  
  */
  getTransferListByTypeOrShift(pages:number,shift:number,petro_id:number){
    return this.http.get<serviceDataSale>(constDefault.API_URL+'/petros/stockTransferList/page:'+pages+'/'+petro_id+'/'+shift+'/'+this.login_year,{headers:this.headersdata})
    //.subscribe(data=>{console.log('ABC',data)})categoryPageList
  }
  
  
   /*
   Get over Due intrest
   05.06.19
   Amit Sahu
  */
  getPreviousInvoiceBalanceIntrest(petro_id:number,searchData:any){
    return this.http.post<getData>(constDefault.API_URL+"/petros/getInvoiceOverDueIntrest/"+petro_id+'/'+this.login_year,searchData,{headers:this.headersdata})
  }
   
   /*
   Get over Due intrest
   05.06.19
   Amit Sahu
  */
  deleteCreditInvoice(id:number){
    return this.http.get<getData>(constDefault.API_URL+"/petros/deleteCreditInvoice/"+id+'/'+this.login_year,{headers:this.headersdata})
  }
  
   /*
   Get Stock by item
   11.06.19
   Amit Sahu
  */
  getStockItemLocation(petro_id:number,item_id:number,location_id:number,batch:any){
    return this.http.get<getData>(constDefault.API_URL+"/petros/locationItemWiseStock/"+petro_id+'/'+item_id+'/'+location_id+'/'+this.login_year+'/'+batch,{headers:this.headersdata})
  }
  
  
   /*
  delete shift Sale Transiction (Stock transfer) 
 25.6.2019
 Mrunali D
  */
  deleteStockTransfer(stock_tr_id:number){	
    return this.http.get<deleteData>(constDefault.API_URL+"/petros/deleteStockTransfer/"+stock_tr_id+'/'+this.login_year,{headers:this.headersdata})
  }	
  /*
  Add Vehicle
  25.9.2019
  Mrunali D
  */
  addVehicle(data:any){
    return this.http.post<getData>(constDefault.API_URL+"/commons/addVehicle/"+this.login_year,data)
  }

  
  
/*
    Get sales data for generate invoice 
    7.1.2020
    MD
  */
 getOtherSalesDataForCreateInvoice(searchData){
  return this.http.post<getDataForInvoice>(constDefault.API_URL+"/petros/getCreditOtherSaleItemFoRInvoiceGenerate/"+this.login_year,searchData,{headers:this.headersdata});
}
/*
MD
generate invoice
8.1.2020*/
 generateOtherCreditInvoice(data){
  return this.http.post<getData>(constDefault.API_URL+"/petros/generateOtherCreditInvoice/"+this.login_year,data,{headers:this.headersdata});
}
getOtherInvoiceListData(pages:number, petro_id:number, searchData:any){
	return this.http.post<serviceDataSale>(constDefault.API_URL+'/petros/otherCreditInvoiceList/page:'+pages+'/'+petro_id+'/'+this.login_year,searchData,  {headers:this.headersdata})

  }
/*
MD
Get Invoice data by id
8.1.2020
*/
getOtherInvoiceDataById(invoice_id:number){
  return this.http.get<getData>(constDefault.API_URL+"/petros/getOtherInvoiceDataById/"+invoice_id+'/'+this.login_year,{headers:this.headersdata});
}

   /*
   Get over Due intrest
   8.1.2020
  MD
  */
  deleteOtherCreditInvoice(id:number){
    return this.http.get<getData>(constDefault.API_URL+"/petros/deleteOtherCreditInvoice/"+id+'/'+this.login_year,{headers:this.headersdata})
  }
  //edit slip no
	editSales(data:any){
		return this.http.post<getData>(constDefault.API_URL+"/petros/editSlipNoInSale/"+this.login_year,data, {headers:this.headersdata})
	}
	
	/*
Mrunali D
Get Invoice data by email
25.1.19
*/
sendInvoiceDataMail(invoice_id:number){
  return this.http.get<getData>(constDefault.API_URL+"/petros/sendInvoiceDataMail/"+invoice_id+'/'+this.login_year,{headers:this.headersdata});
}
	
}
