import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MasterServicesService } from '../../../admin-services/master-services.service';
import { ReportsService } from '../../../admin-services/reports.service';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { CommonsService } from '../../../admin-services/commons.service';
import { FormGroup,FormControl,Validators ,FormBuilder,FormArray} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { constDefault } from '../../../const_default';
import { DatePipe } from '@angular/common';
// Date Picker
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';

declare var $: any;

@Component({
  selector: 'app-bozerstock',
  templateUrl: './bozerstock.component.html',
  styleUrls: ['./bozerstock.component.css']
})
export class BozerstockComponent implements OnInit {

  constructor(private reportsService: ReportsService,private session:SessionStorageService,private router:Router,private commonsServices: CommonsService, private exportAsService:ExportAsService,private masterService: MasterServicesService) { }
 exportAsConfig: ExportAsConfig;
	  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select  Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
  authData:any={};
  serachReportData:any={};	
  reportData: any[] = [];
  config:any;
  categoryDropdownList: any[] = [];
  petro_id:number;
  success_message:string;
  error_message:string;
  pump_name:number;
  pump_address:string;
  pump_mobile_no:number;
  pipe:any;
  credit_sale:number;
  cash_sale:number;
  search_type:number;
show_loading_image:boolean;
  group_name:string;
  row_total_array:any={};
  
  searchReportForm= new FormGroup({
		from_date : new FormControl(' ',Validators.required),
		to_date : new FormControl(' ',Validators.required),
		tmp_group_id : new FormControl(' ',Validators.required),
		type : new FormControl(' ',Validators.required),
		sale_type : new FormControl(' ')
	});

  ngOnInit() {
    this.authData = this.session.get('authData');
	 	this.petro_id = this.authData.petro_id;
			this.credit_sale = constDefault.CREDIT_SALE;
	 	this.cash_sale = constDefault.CASH_SALE;
	 	//console.log(this.authData);
	 	this.pump_name=this.authData.petro_name;
	 	this.pump_mobile_no=this.authData.petro_phone_no;
	 	this.pump_address=this.authData.petro_address;
		this.serachReportData.from_date=new Date(Date.now());
		this.serachReportData.to_date=new Date(Date.now());
		this.serachReportData.type=0;
		this.commonsServices.getBozerList(this.petro_id)
			.subscribe(catlistdata => {
				//this.categoryDropdownList = catlistdata.data
				this.categoryDropdownList = Object.entries(catlistdata.data).map(([value, label]) => ({
				value,
				label
			}));
			});

  }


  getReportData() {
	  this.pipe = new DatePipe('en-US'); 
	 // this.search_type=this.serachReportData.type;
	  this.serachReportData.from_date= this.pipe.transform(this.serachReportData.from_date, 'yyyy-MM-dd');		
	  this.serachReportData.to_date= this.pipe.transform(this.serachReportData.to_date, 'yyyy-MM-dd');		
		if(this.serachReportData.tmp_group_id)
		{
			this.serachReportData.group_id=this.serachReportData.tmp_group_id.value;
			this.group_name=this.serachReportData.tmp_group_id.label;
		}
	 this.reportsService.getBowzerWiseSaleData(this.petro_id,this.serachReportData)
	  .subscribe(reportAllData => {
	  // console.log(purchaseListData);
	   if(reportAllData.status==200)
		  {
			 this.search_type=reportAllData.data.type;
			 
				this.reportData=reportAllData.data.SaleData;
			 
			
		  }
		  else if(reportAllData.status==403)
			{
				  this.session.remove('authData');
					this.router.navigate(['']);
			}else{
			this.reportData=[];
			 this.total_qty=0;
        this.total_amount=0;
		  }
	   
	  });
	}

  total_qty:number;
	total_amount:number;
	 getTotal(amount,qty,i){
   
    if(i==0)   
    {
        this.total_qty=0;
        this.total_amount=0;
        
       // console.log(this.row_total);
    }

     if(amount==1 || amount==0){
		this.total_qty=Number(this.total_qty)+Number(qty);
		this.row_total_array[i]= Number(this.total_qty);  
	 } else{
		this.total_qty=Number(this.total_qty)-Number(qty);
		this.row_total_array[i]= Number(this.total_qty);  
	 }

   // this.total_qty=Number(this.total_qty)+Number(qty);
    this.total_amount=Number(this.total_amount)+Number(amount);
  }
  exportTable(event) {
    if(event.target.value)
    {
    this.exportAsConfig = {
      type: event.target.value, // the type you want to download
      elementId: 'exportTable', // the id of html/table element
    }	
      //this.exportTableDirective.exportData();
      // download the file using old school javascript method
      this.exportAsService.save(this.exportAsConfig, 'bowzer_wise_stock_sale_report');
      // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
      this.exportAsService.get(this.config).subscribe(content => {
      
      });
    }
    }
  // Print search data
    printComponent() {
      var printContents = document.getElementById("printData").innerHTML;
      var popupWin = window.open('', '_blank', 'width=300,height=300');
      popupWin.document.open();
      popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
      popupWin.document.close();
    }

}
