import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { constDefault } from '../const_default';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

interface listData {
	data: any;
	msg:string;
	status:number;
}

interface booleanData {
  status:Boolean;
}

@Injectable({
  providedIn: 'root'
})

export class CommonsService {
	headersdata:any={};
		authValue:any={};
		user_id:string;
		login_token:string;
		login_year:string;

  constructor(private http: HttpClient, private session:SessionStorageService, private local_storage:LocalStorageService) { 

	this.authValue = this.local_storage.get('localAuthData');
	if(this.authValue)
	{
	//console.log(this.authValue);
	this.user_id=this.authValue.user_id;
	this.login_token=this.authValue.token;
	this.login_year=this.authValue.financial_year_id;
	 //console.log(this.login_year);
	let headers: HttpHeaders = new HttpHeaders();
	headers = headers.append('Accept', 'application/json');
	headers = headers.append('user', this.user_id);
	headers = headers.append('year', this.login_year);
	this.headersdata = headers.append('token', this.login_token);
	}
}
    /*
  Gst Slab Dropdown
  19.11.18
  Amit Sahu  
  */
  getGstSlabDropdown(){
	
	  //configUrl="http://localhost/society/mobile/login";
	return this.http.get<listData>(constDefault.API_URL+"/commons/gstDropList/"+this.login_year,{headers:this.headersdata})
	//.subscribe(data=>{console.log('ABC',data)})
	
  }
  /*
  Unit Dropdown
  19.11.18
  Amit Sahu  
  */
  getUnitDropdown(){		
	return this.http.get<listData>(constDefault.API_URL+"/commons/unitDropList/"+this.login_year,{headers:this.headersdata})	
  }
  
	/* 
		Get States List
		Roshan Bagde
	*/
	getStates(country_id:number){
		return this.http.get<listData>(constDefault.API_URL+"/commons/stateList/"+country_id+'/'+this.login_year,{headers:this.headersdata})
	}

	


	/*
	  Get Cities List
		Roshan Bagde
	*/
		getCities(state_id:number){
		return this.http.get<listData>(constDefault.API_URL+"/commons/cityList/"+state_id+'/'+this.login_year,{headers:this.headersdata})
	}
	/* 
	  Get Item List
	  Roshan Bagde
	*/
	getIteamLsit(petro_id:number){
		return this.http.get<listData>(constDefault.API_URL+"/commons/itemDropList/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
	}
	
	/*
		Get Commodity List
		Amit Shau
	*/
	getCommodityLsit(petro_id:number){
		return this.http.get<listData>(constDefault.API_URL+"/commons/commodityDropList/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
	}
	
	/*
	  Unique fileds
	  Amit Shau
	*/
	uniqueByModalOrName(modal:string,name:string,petro_id:number){
		return this.http.get<booleanData>(constDefault.API_URL+"/commons/nameAlreadyExist/"+modal+"/"+name+"/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
	}
	 
	/*
	  Amit Shau 
	  Get item by name for auto sugest 
	*/
		getItemByName(name:string,petro_id:number){
		return this.http.get<listData>(constDefault.API_URL+"/commons/searchItemByName/"+name+'/'+petro_id+'/'+this.login_year,{headers:this.headersdata})
	}
	
	/*
	  Amit Shau 
	  Get item by name for auto sugest with type
	*/
		getItemByNameOrType(name:string,petro_id:number,protuct_type:number){
		return this.http.get<listData>(constDefault.API_URL+"/commons/searchItemByNameOrType/"+name+'/'+petro_id+'/'+protuct_type+'/'+this.login_year,{headers:this.headersdata})
	}

	/*
	  Roshan Bagde
	  Get Dispenser List
	*/
		getDispenserDropdown(petro_id:number){		
		return this.http.get<listData>(constDefault.API_URL+"/commons/dispenserDropList/"+petro_id+'/'+this.login_year,{headers:this.headersdata})	
		}
		
		
	/*	
	  Amit Sahu	  
    Get Commodity List
	*/
		getTankByCommodityWise(petro_id:number,commodity_id:number){
		return this.http.get<listData>(constDefault.API_URL+"/commons/itemWiseTankList/"+petro_id+'/'+commodity_id+'/'+this.login_year,{headers:this.headersdata})
	}

	/*
		Roshan Bagde
		Get Customer Dropdown list
	*/ 
	getCustomerDropDownList(petro_id:number){
		return this.http.get<listData>(constDefault.API_URL+"/commons/customerDropList/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
	}
	
	/*
		Roshan Bagde
		Get Ledger List By Group
	*/	
	getLedgerListByGroup(group_id:number,petro_id:number){
		return this.http.get<listData>(constDefault.API_URL+"/commons/groupWiseledgerList/"+group_id+'/'+petro_id+'/'+this.login_year,{headers:this.headersdata})
	}

	/*
  	Amit Sahu
  	Get Customer Data By Edit
		19.11.18
	*/ 
  getCustomerDataById(cust_id:number){
		return this.http.get<listData>(constDefault.API_URL+"/masters/getCustomerDataById/ "+cust_id+'/'+this.login_year,{headers:this.headersdata})
	}

	/*Get Card Name list
		Roshan Bagde
		21-12-18
	*/
	getCardNameList(shift_no:number,petro_id:number,card_type:number){
		return this.http.get<listData>(constDefault.API_URL+"/commons/cardNameDropList/"+petro_id+"/"+card_type+'/'+this.login_year,{headers:this.headersdata})
	}

	getpgCardNameList(shift_no:number,petro_id:number,card_type:number){
		return this.http.get<listData>(constDefault.API_URL+"/response/cardNameDropList/"+petro_id+"/"+card_type+'/'+this.login_year,{headers:this.headersdata})
	}

	// Get Nozzle list
	getNozzleDropdownList(petro_id:number){
		return this.http.get<listData>(constDefault.API_URL+"/commons/nozzleDropdownList/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
	}

	/* Parent group data list
		Roshan Bagde
		27-12-18
	*/	 
	getParentGroupList(petro_id:number){
		return this.http.get<listData>(constDefault.API_URL+"/commons/groupDropdownList/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
	}
	
	/* Parent group data list
		Roshan Bagde
		27-12-18
	*/	 
	financialYear(){
		return this.http.get<listData>(constDefault.API_URL+"/commons/financialYearDropList",{headers:this.headersdata})
	}

	/*
		Amit Shau 
		Get item by name for auto sugest
		12/12/2019  
	*/
	getLedgerByName(name:string,petro_id:number){
		return this.http.get<listData>(constDefault.API_URL+"/commons/getLedgerByName/"+name+'/'+petro_id+'/'+this.login_year,{headers:this.headersdata})
	}
/*
		Amit Shau 
		Get item by name for auto sugest by group
		12/12/2019  
	*/
	getLedgerByNameOrGroupId(name:string,petro_id:number,group_id:number){
		return this.http.get<listData>(constDefault.API_URL+"/commons/getLedgerByNameOrGroup/"+name+'/'+petro_id+'/'+group_id+'/'+this.login_year,{headers:this.headersdata})
	}
	/* 
	Roshan Bagde
	get group_id by ledger name
	31/01/2019
	*/
	getGroupIdByLedgerId(id:number,petro_id:number){
		return this.http.get<listData>(constDefault.API_URL+"/commons/getGroupIdByLedgerId/"+id+'/'+petro_id+'/'+this.login_year,{headers:this.headersdata})
	}

	/*
		Get Designation Dropdown List
		14/01/2019
		Roshan Bagde
	*/ 
	getDesignations(petro_id:number){
		return this.http.get<listData>(constDefault.API_URL+"/commons/designationDropList/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
	}

	/*
		Get Financial Drop Down List
		15/01/2019
		Roshan Bagde
	*/
	getFinancialYear(){
		return this.http.get<listData>(constDefault.API_URL+"/commons/financialYearDropList",{headers:this.headersdata});
	}

	/*
		Get Card Drop Down List
		20/01/2019
		Roshan Bagde
	*/
	getCashAndCardList(petro_id:number){
    return this.http.get<listData>(constDefault.API_URL+"/commons/cardCustDropList/"+petro_id+'/'+this.login_year,{headers:this.headersdata});
  }
  
  /*
		Get Location List By Petro ID
		29/02/2019
		Amit Sahu
	*/
	getLocationListByPetroId(petro_id:number){
    return this.http.get<listData>(constDefault.API_URL+"/commons/getLocationList/"+petro_id+'/'+this.login_year,{headers:this.headersdata});
  }
  
   /*
		Get General setup data
		09-04-2019
		Amit Sahu
	*/
	applicationGeneralData(petro_id:number){
    return this.http.get<listData>(constDefault.API_URL+"/commons/applicationGeneralData/"+petro_id+'/'+this.login_year,{headers:this.headersdata});
  }
   /*
    get invoice  setup details
    20.02.19
   Amit Sahu  
  */
  
  getInvoiceSetupData(petro_id:number){
		return this.http.get<listData>(constDefault.API_URL+"/adminSetups/getInvoiceSetupData/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
  }
  /*Amit sahu
  export gstr one
  26.04.2019*/
  exportGstrOne(searchdata:any){
		return this.http.post<listData>(constDefault.API_URL+"/reports/gstGstrOne/"+this.login_year,searchdata,{headers:this.headersdata})
  }
/*
	  Unique email
	  Amit Shau
	*/
	uniqueEmailid(email:string){
	
		return this.http.get<booleanData>(constDefault.API_URL+"/commons/emailAlreadyExist/"+email+'/'+this.login_year)
	}
	/*
	  Unique mobile
	  Amit Shau
	*/
	uniqueMobile(email:string){
	
		return this.http.get<booleanData>(constDefault.API_URL+"/commons/mobileAlreadyExist/"+email+'/'+this.login_year)
	}
		  /*
 contry dropdown
 
  
  */
  getcountryDropdownList(){		
	return this.http.get<listData>(constDefault.API_URL+"/commons/countryList/"+this.login_year)	
  }
  /*
  Mrunali D 
  18.9.2019
  */
 
  
  getVehicleList(petro_id:number,ledger_id:number){
		return this.http.get<listData>(constDefault.API_URL+"/commons/getVehicleList/"+petro_id+'/'+ledger_id+'/'+this.login_year,{headers:this.headersdata})
	}

	getBozerList(petro_id:number){
		return this.http.get<listData>(constDefault.API_URL+"/commons/getBozerList/"+petro_id+'/'+this.login_year,{headers:this.headersdata})
	}
		/*
		Mrunali D
		get dsm list
		12/12/2019  
	*/
	getDsmByName(petro_id:number,day_shift:number,date:any){
		return this.http.get<listData>(constDefault.API_URL+"/commons/dsmShiftWiseList/"+petro_id+'/'+day_shift+'/'+date+'/'+this.login_year,{headers:this.headersdata})
	}

	getDsmByNameDate(petro_id:number,date:any,date2:any){
		return this.http.get<listData>(constDefault.API_URL+"/commons/dsmDateWiseList/"+petro_id+'/'+date+'/'+date2+'/'+this.login_year,{headers:this.headersdata})
	}

}