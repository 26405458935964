import { Component, OnInit } from '@angular/core';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { FormGroup,FormControl,Validators ,FormBuilder} from '@angular/forms';
import { DatepickerOptions } from 'ng2-datepicker';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import * as enLocale from 'date-fns/locale/en';
import{DatePipe}from '@angular/common';
import { SaleServiceService } from '../../../admin-services/sale-service.service';
import { CommonsService } from '../../../admin-services/commons.service';
// Custoem Function
import { OnCreatedDirective } from '../../../shared/on-created.directive';
import { Router} from '@angular/router';
@Component({
  selector: 'app-shift-report',
  templateUrl: './shift-report.component.html',
  styleUrls: ['./shift-report.component.css']
})
export class ShiftReportComponent implements OnInit {
  total_sale_other:number;
  total_sale_ms:number;
  total_sale_hsd:number;
 
  total_cash_other:number;
  total_cash_ms:number;
  total_cash_hsd:number;
 
  total_credit_other:number;
  total_credit_ms:number;
  total_credit_hsd:number;
  
  ms_hsd_total:number;
  credit_total:number;
   other_sale_total:number;
   bank_total:number;
   sale_total:number;
   cash_sale_total:number;
   credit_sale_total:number;
   receipt_total:number;
   cardreceipt_total:number;
   expenses_total:number;
   row1:number;
   mshsd_cash_total:number;
   mshsd_credit_total:number;
    ms_hsd_sale:number;
  ms_hsd_cash_sale:number;
  ms_hsd_credit_sale:number;
  ms_hsd_sale_total:number;
  meter_bowsersale:number;
  meter_bowserallsale:number;
  show_bozer:number

  card_total:number;
  wallet_total:number;
   setDob:string;
   totalamount:number;
   authData:any={};
   petro_id:number;
   pump_name:string;
   pump_address:string;
   shift_no:number;
    receiptData:any=[];
  cardreceiptData:any=[];
 expensesData:any=[];
   shift_detail:any[]=[];
   row_total_array:any={};
   config:any;
  amount_data:number;
   ledger_is_debit:number;
   credit:any[]=[];
   bank:any[]=[];
   mshsddetails:any[]=[];

   receipt:any[]=[];
   othersale:any[]=[];
   bozerrefill:any[]=[];
   DailyShift:any[]=[];
   shiftsummary:any[]=[];
   reportData: any[] = [];
   dipReading: any[] = [];
   reportData1: any[] = [];
   totalCardSaleDtl: any[] = [];
   totalWalletSaleDtl: any[] = [];
   serachShiftReportData:any={};	
   merged:any[]=[];
   merged1:string;
   genelarGeneralSetup:any[]=[];
   

  constructor(private router:Router,private exportAsService:ExportAsService, private session:SessionStorageService,private saleServiceService:SaleServiceService, private commonsServices: CommonsService) { }
  exportAsConfig: ExportAsConfig;
  
  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'YYYY-MM-DD',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		//locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
   
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select  Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };

	searchShiftReportForm= new FormGroup({
		from_date : new FormControl(' ',Validators.required),
		shift_no : new FormControl(' ',Validators.required),
		tmp_ledger_id : new FormControl(' ')
		
  });	

  ngOnInit() {
    this.authData = this.session.get('authData');
    this.petro_id = this.authData.petro_id;
    this.serachShiftReportData.from_date=new Date();
    this.pump_name=this.authData.petro_name;	
	 	this.pump_address=this.authData.petro_address;

   

  }


  resetSearchShiftReport(){
    
	  
    this.serachShiftReportData.from_date=new Date();
  this.serachShiftReportData.shift_no='';
  this.reportData=[];
  this.credit=[];
  this.bank=[];
  this.othersale=[]; 
  this.shiftsummary=[];
  this.dipReading=[];
  this.shift_detail=[];
  this.mshsddetails=[];
  this.totalCardSaleDtl=[];
  this.totalWalletSaleDtl=[];

  this.receiptData=[];
  this.cardreceiptData=[];
  this.expensesData=[];
  this.ms_hsd_total=0.00;
  this.credit_total=0.00;
  this.other_sale_total=0.00;
  this.bank_total=0.00;
  this.sale_total=0.00;
  this.cash_sale_total=0.00;
  this.credit_sale_total=0.00;
  this.receipt_total=0.00;
  this.cardreceipt_total=0.00;
  this.expenses_total=0.00;
  this.mshsd_cash_total=0.00;
  this.mshsd_credit_total=0.00;
  this.total_cash_other=0.00;
  this.total_credit_other=0.00;
  this.ms_hsd_sale=0;
  this.ms_hsd_cash_sale=0;
  this.ms_hsd_credit_sale=0;
  this.card_total=0.00;
  this.wallet_total=0.00;
}

hsd_sale:number;
ms_sale:number;
  searchShiftReport(){
    this.show_bozer=0;
    this.commonsServices.applicationGeneralData(this.petro_id)
		.subscribe(listdata =>{ 	
		this.genelarGeneralSetup=listdata.data
		this.show_bozer=listdata.data.AppSetupGeneral.show_bozer;
		

		});
    var datePipe =  new DatePipe('en-US');
    this.setDob = datePipe.transform(this.serachShiftReportData.from_date, 'yyyy-MM-dd');
    //console.log(this.setDob);
    this.saleServiceService.getShiftReportData(this.serachShiftReportData.shift_no,this.setDob,this.petro_id)
   
  .subscribe(purchaseListData => {
   
   if(purchaseListData.status==200)
    {
   this.reportData=purchaseListData.data;
   this.bozerrefill=purchaseListData.bozerRefillData;
    this.othersale=purchaseListData.otherSale;
    this.credit=purchaseListData.creditSale;
    this.receiptData=purchaseListData.receiptData;
    this.cardreceiptData=purchaseListData.cardreceiptData;
    this.expensesData=purchaseListData.expenseData;
	this.bank=purchaseListData.bankDeposit;
	this.mshsddetails=purchaseListData.mshsdDetails;
	this.shiftsummary=purchaseListData.shiftSummary;
	this.dipReading=purchaseListData.tank_wise_dip_reading;
  this.totalCardSaleDtl=purchaseListData.totalCardSaleDtl;
  this.totalWalletSaleDtl=purchaseListData.totalWalletSaleDtl;


	this.ms_hsd_sale_total=0;
	this.hsd_sale=0;
	this.ms_sale=0;
	if(purchaseListData.mshsdDetails[0])
	{
		if(purchaseListData.mshsdDetails[0].sale_amount){
			this.hsd_sale=purchaseListData.mshsdDetails[0].sale_amount;
		}
		if(purchaseListData.mshsdDetails[1].sale_amount){
			this.ms_sale=purchaseListData.mshsdDetails[1].sale_amount;
		}
		this.ms_hsd_sale_total=Number(this.ms_sale)+Number(this.hsd_sale)-Number(purchaseListData.shiftSummary.bozer_amt);
	}
	this.meter_bowsersale=Number( purchaseListData.shiftSummary.DailyShift.total_sale)+Number(purchaseListData.shiftSummary.bozer_amt)-Number(purchaseListData.mshsdDetails[2].sale_amount);
	this.meter_bowserallsale=Number( purchaseListData.shiftSummary.DailyShift.total_sale)+Number(purchaseListData.shiftSummary.bozer_amt);
 
}else if(purchaseListData.status==403)
{
    this.session.remove('authData');
    this.router.navigate(['']);
} 
else{
      this.merged=[];
	    this.ms_hsd_total=0.00;
  this.credit_total=0.00;
  this.other_sale_total=0.00;
  this.bank_total=0.00;
  this.sale_total=0.00;
  this.cash_sale_total=0.00;
  this.credit_sale_total=0.00;
  this.receipt_total=0.00;
  this.cardreceipt_total=0.00;
  this.expenses_total=0.00;
  this.mshsd_cash_total=0.00;
  this.mshsd_credit_total=0.00;
  this.total_cash_other=0.00;
  this.total_credit_other=0.00;
  this.ms_hsd_sale=0;
  this.ms_hsd_cash_sale=0;
  this.ms_hsd_credit_sale=0;
  this.card_total=0.00;
  this.wallet_total=0.00;
    }
  });
  }

  getTotal(amount,i){
   
    if(i==0)   
    {
        this.ms_hsd_total=0;
        
       // console.log(this.row_total);
    }
    this.ms_hsd_total=Number(this.ms_hsd_total)+Number(amount);
  }

  getcredit(total_amount,i){

    if(i==0)   
    {
        this.credit_total=0;
        //console.log(this.row_total1);
        
    }
    this.credit_total=Number(this.credit_total)+Number(total_amount);
  }

  getcardSale(total_amount,i){

    if(i==0)   
    {
        this.card_total=0;
        //console.log(this.row_total1);
        
    }
    this.card_total=Number(this.card_total)+Number(total_amount);

    //this.getBalCash();
  }

  getwalletSale(total_amount,i){

    if(i==0)   
    {
        this.wallet_total=0;
        //console.log(this.row_total1);
        
    }
    this.wallet_total=Number(this.wallet_total)+Number(total_amount);
    //this.getBalCash();
  }

  getothersale(total_amount,i){
   
    if(i==0)   
    {
        this.other_sale_total=0;
        //console.log(this.row_total2);
    }
    this.other_sale_total=Number(this.other_sale_total)+Number(total_amount);
    
  }

  getbank(total_amount,i){
   
    if(i==0)   
    {
        this.bank_total=0;
        //console.log(this.row_total2);
    }
    this.bank_total=Number(this.bank_total)+Number(total_amount);
  }

  getcardreceipt(total_amount,i){
   
    if(i==0)   
    {
        this.cardreceipt_total=0;
        //console.log(this.row_total2);
    }
    this.cardreceipt_total=Number(this.cardreceipt_total)+Number(total_amount);
  }


  getreceipt(total_amount,i){
   
    if(i==0)   
    {
        this.receipt_total=0;
        //console.log(this.row_total2);
    }
    this.receipt_total=Number(this.receipt_total)+Number(total_amount);
  }

  getexpenses(total_amount,i){
   
    if(i==0)   
    {
        this.expenses_total=0;
        //console.log(this.row_total2);
    }
    this.expenses_total=Number(this.expenses_total)+Number(total_amount);
  }
 
  getMsHsdTotal(sale_amt,cash_sale_amt,credit_sale_amt,i){
   
    if(i==0)   
    {
        this.ms_hsd_sale=0;
        this.ms_hsd_cash_sale=0;
        this.ms_hsd_credit_sale=0;
      
    }
    this.ms_hsd_sale=Number(this.ms_hsd_sale)+Number(sale_amt);
    this.ms_hsd_cash_sale=Number(this.ms_hsd_cash_sale)+Number(cash_sale_amt);
    this.ms_hsd_credit_sale=Number(this.ms_hsd_credit_sale)+Number(credit_sale_amt);
  }
  exportTable(event) {
    if(event.target.value=='xlsx')
    {
    this.exportAsConfig = {
      type: event.target.value, // the type you want to download
      elementId: 'exportTable', // the id of html/table element
    }	
      //this.exportTableDirective.exportData();
      // download the file using old school javascript method
      this.exportAsService.save(this.exportAsConfig, 'Shift Report ');
      // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
      this.exportAsService.get(this.config).subscribe(content => {
      
      });
    }
  }
  	printReport() {
		var printContents = document.getElementById("exportTable").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}
   
}
