export class constDefault {
    //	const abc=1;
    //public static API_URL='http://182.18.165.73/cake_php2';
    //public static API_URL='http://localhost:8091/petro_cake';
    //public static API_URL='http://182.18.165.73/cake_php2';
    public static API_URL='https://erp.bizpetro.com/petro_cake';
    //public static API_URL='http://192.168.1.132:8091/petro_cake';
    //DEFAULT ACCOUNT GROUP 
    public static SUNDRY_DEBTOR_GROUP=26;
    public static SUNDRY_CREDITOR_GROUP=25;
    public static BANK_ACCOUNTS_GROUP=15;
    public static CARD_MASTER_GROUP=3;
    
    //GST TYPE
    public static GST_TYPE_CGST=0;
    public static GST_TYPE_IGST=1;

    // Sale Type 
    public static CASH_SALE=0;
    public static CREDIT_SALE=1;
    
    // LEdger Is Debit Or credit
    public static DEBIT_LEDGER=1;
    public static CREDIT_LEDGER=2;
    
    // Gender constant values
    public static GENDER_MALE=1;
    public static GENDER_FEMALE=2;
    
    // Maritial Status constants
    public static MARITIAL_STATUS_MARRIED=1;
    public static MARITIAL_STATUS_UNMARRIED=2;
    public static MARITIAL_STATUS_OTHER=3;
    
    // Default Ledger
    public static CASH_LEDGER=1;
    
    // ItemCategory Constance
    public static ITEM_CATEGORY_MS=1;
    public static ITEM_CATEGORY_HSD=2;
    public static ITEM_CATEGORY_BMS=3;
    public static ITEM_CATEGORY_BHSD=4;
    
    // Current financial year
    public static CURRENT_FINANCIAL_YEAR = 3;
    
    // Miscellaneous Customer
    public static MISCELLANEOUES_LEDGER = 14;
    
    //PURCHASE TYPE
    public static PURCHASE_TYPE_OTHER=0;
    public static PURCHASE_TYPE_MSHSD=1;
    
    // Item  Type Constance
    public static ITEM_TYPE_MSHSD=1;
    public static ITEM_TYPE_OTHER=2;
    
    // Item  Type Constance
    public static SALE_TYPE_CARD=0;
    public static SALE_TYPE_WALLET=1;
    
    // Vocher Type constace
    public static VOCHER_TYPE_PAYMENT=1;
    public static VOCHER_TYPE_CONTRA=2;
    public static VOCHER_TYPE_RECEIPT=3;
    public static VOCHER_TYPE_JOURNAL=4;
    public static VOCHER_TYPE_ADVANCE=5;
    public static VOCHER_TYPE_PURCHASE=6;
    public static VOCHER_TYPE_SALE=7;
    public static VOCHER_TYPE_DEBIT_NOTE=8;
    public static VOCHER_TYPE_CREDIT_NOTE=9;
    
    // Location constace
    public static LOCATION_GODOWN=1;
    public static LOCATION_SHOWROOM=2;
    
    
    // Nav related constance
    public static GSTR_ONE_NAV=44;
    public static GSTR_THREEB_NAV=45;
    
    //Designation
    public static DESIGNATION_ACCOUNTANT=4;
    public static DESIGNATION_MANAGER=3;
    public static DESIGNATION_SHIFT_INCH=1;
    
    }
                                                                        