import { Component, OnInit } from '@angular/core';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { FormGroup,FormControl,Validators ,FormBuilder,FormArray} from '@angular/forms';
import { DatepickerOptions } from 'ng2-datepicker';
import { constDefault } from '../../../const_default';
import * as enLocale from 'date-fns/locale/en';
import { OnCreatedDirective } from '../../../shared/on-created.directive';
import { SaleServiceService } from '../../../admin-services/sale-service.service';
import{DatePipe}from '@angular/common';
import { Router} from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-gst-sale-report',
  templateUrl: './gst-sale-report.component.html',
  styleUrls: ['./gst-sale-report.component.css']
})
export class GstSaleReportComponent implements OnInit {
	exportAsConfig: ExportAsConfig;
	authData:any={};
	petro_id:number;
	customer_id:number;
   pump_name:string;
   gstNO:string;
   pump_address:string;
    show_loading_image:boolean;
	pipe:any;
	config:any;
	 reportData: any[] = [];
   serachData:any={};
	row_total_array:any={};
  constructor(private router: Router,private saleServiceService:SaleServiceService, private session:SessionStorageService,private exportAsService:ExportAsService) { }
options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		//locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
   
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select  Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };

  searchForm= new FormGroup({
		from_date : new FormControl(' ',Validators.required),
		to_date : new FormControl(' ',Validators.required),
		tmp_customer_id : new FormControl(' ',Validators.required)
		
	});
  ngOnInit() {
	  this.authData = this.session.get('authData');
		this.petro_id = this.authData.petro_id;
		this.serachData.from_date=new Date(Date.now());
		this.serachData.to_date=new Date(Date.now());
		this.pump_name=this.authData.petro_name;	
		this.pump_address=this.authData.petro_address;

		this.saleServiceService.getPetrolPumpData(this.petro_id).subscribe(data=>{
        this.gstNO=data.data.Petrolpump.gstn_no;
		});


  }
  searchReport(){
	  this.show_loading_image=true;
	 
		this.pipe = new DatePipe('en-US'); 
		this.searchForm.value.from_date = this.pipe.transform( this.searchForm.value.from_date, 'yyyy-M-d');
		this.searchForm.value.to_date = this.pipe.transform( this.searchForm.value.to_date, 'yyyy-M-d');
	  
	  this.saleServiceService.getSaleGstReportData(this.petro_id,this.searchForm.value)
			.subscribe(statementData => {
				if(statementData.status==200)
				{
					//console.log(vehicleData);
					this.reportData = statementData.data;
					this.show_loading_image=false;
					
				}else{
					this.reportData=[];
					this.show_loading_image=false;
					
				}
				
			});
  }
  sub_total:number;
  cgst_total:number;
  sgst_total:number;
  igst_total:number;
  grand_total:number;
  getBalance(sub_total,cgst,sgat,igst,total,i){
	 if(i==0)
		{
			this.sub_total=0;
			this.cgst_total=0;
			this.sgst_total=0;
			this.igst_total=0;
			this.grand_total=0;
			 
		}
	this.sub_total=Number(this.sub_total)+Number(sub_total);
	this.cgst_total=Number(this.cgst_total)+Number(cgst);
	this.sgst_total=Number(this.sgst_total)+Number(sgat);
	this.igst_total=Number(this.igst_total)+Number(igst);
	this.grand_total=Number(this.grand_total)+Number(total);
	
	 
	 
  }
    exportTable(event) {
	if(event.target.value)
	{
	this.exportAsConfig = {
		type: event.target.value, // the type you want to download
		elementId: 'exportTable', // the id of html/table element
	}	
		//this.exportTableDirective.exportData();
    // download the file using old school javascript method
    this.exportAsService.save(this.exportAsConfig, 'gst_sale_report');
    // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
    this.exportAsService.get(this.config).subscribe(content => {
    
    });
  }
  }
// Print search data
	printComponent() {
		var printContents = document.getElementById("printData").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}
}
