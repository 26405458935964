import { Component, OnInit } from '@angular/core';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { FormGroup,FormControl,Validators ,FormBuilder,FormArray} from '@angular/forms';
import { CommonsService } from '../../../admin-services/commons.service';
import { ShiftSaleService } from '../../../admin-services/shift-sale.service';
import { MasterServicesService } from '../../../admin-services/master-services.service';
import { DatepickerOptions } from 'ng2-datepicker';
import { constDefault } from '../../../const_default';
import * as enLocale from 'date-fns/locale/en';
import { OnCreatedDirective } from '../../../shared/on-created.directive';
import { SaleServiceService } from '../../../admin-services/sale-service.service';
import{DatePipe}from '@angular/common';
import { Router} from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-unbilled',
  templateUrl: './unbilled.component.html',
  styleUrls: ['./unbilled.component.css']
})
export class UnbilledComponent implements OnInit {
  authData:any={};
  filterData:any={};
  advanceSearchData:any={};
  payment_type_heading:any=[];
  vehicleList:any=[];
  customerStatementData:any=[];
  customerList:any=[];
  cust_name:string;
  categoryDropdownList:any=[];
  vouchers_type:any={};
   petro_id:number;
   customer_id:number;
   pump_name:string;
   pump_address:string;
   shift_no:number;
   show_loading_image:boolean;
	pipe:any;
	ledger_is_debit:number;
	ledger_is_credit:number;
	ledger_opening:number;
   reportData: any[] = [];
   serachData:any={};
	row_total_array:any={};
	config:any;

  constructor(private router: Router,private saleServiceService:SaleServiceService, private session:SessionStorageService,private exportAsService:ExportAsService,private formBuilder:FormBuilder, private shiftsaleService: ShiftSaleService, private commonsServices: CommonsService,private masterService: MasterServicesService) { }
  exportAsConfig: ExportAsConfig;
  
  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		//locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
   
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select  Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };

  searchForm= new FormGroup({
		from_date : new FormControl(' ',Validators.required),
		to_date : new FormControl(' ',Validators.required),
		tmp_customer_id : new FormControl(' ',Validators.required)
		
	});
	advanceSearchForm : FormGroup;
	payment_type : FormArray;

  ngOnInit() {
		// Hide loading image
		this.show_loading_image=false;
		this.ledger_is_debit=constDefault.DEBIT_LEDGER;
	    this.ledger_is_credit=constDefault.CREDIT_LEDGER;
		this.authData = this.session.get('authData');
		this.petro_id = this.authData.petro_id;
		this.serachData.from_date=new Date(Date.now());
		this.serachData.to_date=new Date(Date.now());
		this.pump_name=this.authData.petro_name;	
		this.pump_address=this.authData.petro_address;
		//this.payment_type_heading=[{'type':'CASH'},{'type':'CARD'},{'type':'CHEQUE'}];
		this.payment_type_heading={'CASH':'CASH','CARD':'CARD','CHEQUE':'CHEQUE'};
		this.vouchers_type={'1':'Payment','3':'Receipt','7':'Sale'};
		
	this.advanceSearchForm= this.formBuilder.group({
			 payment_type: this.formBuilder.array([]),
			 vehiles: this.formBuilder.array([]),
			 category_ids: this.formBuilder.array([]),
			 voucher_types: this.formBuilder.array([])
			});
			
			
			/// Get Customer List
			/*this.commonsServices.getLedgerListByGroup(constDefault.SUNDRY_DEBTOR_GROUP, this.petro_id)
		.subscribe(customerListData => {
			if (customerListData.data) {
				
				this.customerList = Object.entries(customerListData.data).map(([value, label]) => ({value,label}));
			
			}
		});*/
		this.commonsServices.getLedgerListByGroup(-26,this.petro_id)
				.subscribe(misCustList =>{

          //misCustList.data.append('0:ALL');
          
					this.customerList = Object.entries(misCustList.data).map(([value, label]) => ({value,label}));
          let aid:number=0;
  
      
    
     
   
				})
       // this.serachData.tmp_customer_id.value="0";
        
       // this.serachData.tmp_customer_id="0";
       
  }
  
   onChange(type: string, isChecked: boolean) {
    const paymentTYpeFormArray = <FormArray>this.advanceSearchForm.controls.payment_type;

    if (isChecked) {
      paymentTYpeFormArray.push(new FormControl(type));
    } else {
      let index = paymentTYpeFormArray.controls.findIndex(x => x.value == type)
      paymentTYpeFormArray.removeAt(index);
    }
  }
     onChangeVehicle(type: string, isChecked: boolean) {
    const VehicleNoFormArray = <FormArray>this.advanceSearchForm.controls.vehiles;

    if (isChecked) {
      VehicleNoFormArray.push(new FormControl(type));
    } else {
      let index = VehicleNoFormArray.controls.findIndex(x => x.value == type)
      VehicleNoFormArray.removeAt(index);
    }
  }
       onChangeCategory(cat_id: string, isChecked: boolean) {
    const categoryFormArray = <FormArray>this.advanceSearchForm.controls.category_ids;

    if (isChecked) {
      categoryFormArray.push(new FormControl(cat_id));
    } else {
      let index = categoryFormArray.controls.findIndex(x => x.value == cat_id)
      categoryFormArray.removeAt(index);
    }
  }
onChangeVoucher(vac: string, isChecked: boolean) {
    const voucherFormArray = <FormArray>this.advanceSearchForm.controls.voucher_types;

    if (isChecked) {
      voucherFormArray.push(new FormControl(vac));
    } else {
      let index = voucherFormArray.controls.findIndex(x => x.value == vac)
      voucherFormArray.removeAt(index);
    }
  }
  
  
  get PaymentTypeGroup() {
		return this.advanceSearchForm.get('payment_type')
	}
  // Set Advance serach data
  getAdvanceSerchaData(){
	  if(this.serachData.tmp_customer_id)
	  {
	  	this.customer_id=this.serachData.tmp_customer_id.value;
		this.shiftsaleService.getVehicleListByCustId(this.customer_id, this.petro_id)
			.subscribe(vehicleData => {
				if(vehicleData.data)
				{
				this.vehicleList = vehicleData.data;
				}
			});
			
			 this.masterService.getCategoryListForDropdown(this.petro_id)
	.subscribe(catlistdata =>{ this.categoryDropdownList=catlistdata.data
	
	 });
	  $('#advanceSearchModal').modal('show');
	  }else{
		  alert('Please select a Customer.');
	  }
  }
  
  searchReport(){
	  this.show_loading_image=true;
	  this.ledger_opening=0;
	  this.gt_cr=0;
	  this.gt_dr=0;
	  if(this.searchForm.value.tmp_customer_id)
	  {
		this.cust_name= this.searchForm.value.tmp_customer_id.label;
		   this.pipe = new DatePipe('en-US'); 
		 this.searchForm.value.from_date = this.pipe.transform( this.searchForm.value.from_date, 'yyyy-M-d');
		 this.searchForm.value.to_date = this.pipe.transform( this.searchForm.value.to_date, 'yyyy-M-d');
	  this.searchForm.value.customer_id=this.searchForm.value.tmp_customer_id.value;
	  }
	  this.filterData['SearchData']=this.searchForm.value;
	  this.filterData['AdvanceSearchData']=this.advanceSearchForm.value;
	  this.saleServiceService.getunbilledReport(this.petro_id,this.filterData)
			.subscribe(statementData => {
				if(statementData.status==200)
				{
					//console.log(vehicleData);
					this.customerStatementData = statementData.data;
					this.show_loading_image=false;
					this.ledger_opening=statementData.opening;
				}else{
					this.customerStatementData=[];
					this.show_loading_image=false;
					this.ledger_opening=statementData.opening;
					this.closing_balance=statementData.opening;
				}
				 $('#advanceSearchModal').modal('hide');
			});
  }
  
  /*
  Inline closing balance calculate
  amit sahu
  30.04.2019
  */
  closing_balance:number;
  gt_cr:number;
  gt_dr:number;
  row_total:number;
  getBalance(amount,type,opeing,i,last){
	 if(i==0)
		{
			  this.row_total=opeing;
			  if(opeing>0)
			  {
				   this.gt_dr=opeing;
			  this.gt_cr=0;
			  }else{
				  this.gt_cr=opeing;
				   this.gt_dr=0;
			  }
			 
		}else{
			 this.row_total=this.row_total_array[i-1];
		}
	
	  if(type==this.ledger_is_debit)	 
	  {
	
		this.gt_dr=Number(this.gt_dr)+Number(amount);
		 //this.row_total= Number(this.row_total)+Number(amount);  
		 this.row_total_array[i]= Number(this.row_total)+Number(amount);  
	  }else{
				this.gt_cr=Number(this.gt_cr)+Number(amount);
		  		 //this.row_total= Number(this.row_total)-Number(amount);  
		  		this.row_total_array[i]= Number(this.row_total)-Number(amount);  
	  }
	
	  if(last)
	  {
		this.closing_balance=this.row_total_array[i];
	  }
		
	 
  }
  
    exportTable(event) {
    if(event.target.value=='xlsx')
    {
    this.exportAsConfig = {
      type: event.target.value, // the type you want to download
      elementId: 'exportTable', // the id of html/table element
    }	
      //this.exportTableDirective.exportData();
      // download the file using old school javascript method
      this.exportAsService.save(this.exportAsConfig, 'Shift Report ');
      // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
      this.exportAsService.get(this.config).subscribe(content => {
      
      });
    }
  }
  // Print search data
	printComponent() {
		var printContents = document.getElementById("printData").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}



}
