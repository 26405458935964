import { Component, OnInit } from '@angular/core';
import { AccountReportService } from '../../../admin-services/account-report.service';
import { FormGroup,FormControl,Validators ,FormBuilder} from '@angular/forms';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { constDefault } from '../../../const_default';
import { Router} from '@angular/router';
import { DatePipe } from '@angular/common';

// Custoem Function
import { OnCreatedDirective } from '../../../shared/on-created.directive';

// Export
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

// Date Picker
import { DatepickerOptions } from 'ng2-datepicker';
import * as enLocale from 'date-fns/locale/en';


@Component({
  selector: 'app-customer-cost',
  templateUrl: './customer-cost.component.html',
  styleUrls: ['./customer-cost.component.css']
})
export class CustomerCostComponent implements OnInit {
  options: DatepickerOptions = {
		minYear: 2015,
		maxYear: 2030,
		displayFormat: 'DD-MM-YYYY',
		barTitleFormat: 'MMMM YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		locale: enLocale,
		//minDate: new Date(Date.now()), // Minimal selectable date
    //maxDate: new Date(Date.now()),  // Maximal selectable date
    
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Select  Date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {'width':'100%'}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
    constructor(private accountReportService: AccountReportService, private session:SessionStorageService,private router:Router,private exportAsService:ExportAsService) { }
		exportAsConfig: ExportAsConfig;
	authData:any={};
	reportData: any[] = [];
	
	serachReportData:any={};	
	
	petro_id:number;
	pump_name:number;
	pump_address:string;
	pump_mobile_no:number;
	gt_dr:number;
	gt_cr:number;
	total_closing:number;
	total_closing_cr:number;
	load_more_status:boolean;
	show_loading_image:boolean;
	ledger_is_debit:number;
	ledger_is_credit:number;
	pipe:any;
	config:any;
	searchForm= new FormGroup({
		from_date : new FormControl(' ',Validators.required),
		to_date : new FormControl(' ',Validators.required),
	});	

	show_details:boolean;
	
  ngOnInit() {
	        	this.dr_opening=0;
				this.cr_opening=0;
				this.cr_closing=0;
				this.dr_closing=0;
                 this.opening_total=0;
				 this.total_closing_cr = 0;
				this.total_closing=0;
	  this.authData = this.session.get('authData');
	  this.petro_id = this.authData.petro_id;
	  this.ledger_is_debit=constDefault.DEBIT_LEDGER;
	  this.ledger_is_credit=constDefault.CREDIT_LEDGER;
	  
	  this.serachReportData.from_date=new Date(Date.now());
	  this.serachReportData.to_date=new Date(Date.now());
		this.show_details=false;
		//console.log(this.authData);
		this.pump_name=this.authData.petro_name;
		this.pump_mobile_no=this.authData.petro_phone_no;
		this.pump_address=this.authData.petro_address;
		//this.getReportData();
  }
    //Get Report Data
	searchReport() {
	        	this.dr_opening=0;
				this.cr_opening=0;
				this.cr_closing=0;
				this.dr_closing=0;
                 this.opening_total=0;
				 this.total_closing_cr = 0;
				this.total_closing=0;
				 this.gt_dr=0;
				    this.gt_cr=0;
	this.show_loading_image=true;
	this.load_more_status=true;
	 this.pipe = new DatePipe('en-US'); 
	  this.serachReportData.from_date= this.pipe.transform(this.serachReportData.from_date, 'yyyy-MM-dd');		
	  this.serachReportData.to_date= this.pipe.transform(this.serachReportData.to_date, 'yyyy-MM-dd');
	 this.accountReportService.getCustomerOutrstandingCostData(this.petro_id,this.serachReportData)
	  .subscribe(reports => {
	  // console.log(purchaseListData);
	
	  if(reports.status==200)
		  {
			
				  this.reportData=reports.data;	
				 
				// this.gt_dr=reports.totalArr.final_dr_amt;	
				// this.gt_cr=reports.totalArr.final_cr_amt;	
			  
			  }else{
				  this.reportData=[];
				  
		  }
		     this.show_loading_image=false;
		   this.load_more_status=false;
	   
	  });
	

	}
	
	
	dr_opening:number;
	cr_opening:number;
	dr_closing:number;
	cr_closing:number;

	opening_total:number;
	showDetails(event){
		if(event.target.checked){
			
			this.show_details=true;
			
		}else{
			
			this.show_details=false;
		
		}
		this.searchReport();
	}

  goLedgerReportpage(ledger_id,ledger_name){
		//alert(ledger_id);
		this.serachReportData.ledger_id=ledger_id;
		this.serachReportData.ledger_name=ledger_name;
		this.session.set('trialVoucherSession',this.serachReportData);
		this.router.navigate(['admin/ledgerReport']);
	}

  getTotal(opening,opening_type,dr_amt,cr_amt,closing,index){
	
			if(index==0)
			{
				this.opening_total=0;	
				this.total_closing=0;
				this.dr_opening=0;
				this.cr_opening=0;
				this.cr_closing=0;
				this.dr_closing=0;
			}
		
			//Opening Total
			if(!opening)
			{
				opening=0;
			}else if(opening == NaN){opening=0;}
	
			if(!closing){
				closing=0;
			}
			
			//totaldr
			
				this.gt_dr=Number(this.gt_dr)+Number(dr_amt);
			
				this.gt_cr=Number(this.gt_cr)+Number(cr_amt);
				
			
			
			if(opening >= 0)
			{
				this.dr_opening=this.dr_opening+opening;
		
			}else{
				this.cr_opening=this.cr_opening+opening;
				
			}
			
			this.opening_total=this.dr_opening-this.cr_opening;
			
		
			//closing
			if (closing >= 0) {
				this.dr_closing = Number(this.dr_closing) + Number(closing);
				this.total_closing = this.total_closing + Number(closing);
			}
			else {
				this.cr_closing = Number(this.cr_closing) + Number(closing);
				this.total_closing_cr = this.total_closing_cr + Number(closing);
			}
		//	this.total_closing=Number(this.dr_closing)-Number(this.cr_closing);
		//	this.total_closing=	this.opening_total+this.gt_dr-this.gt_cr;
  }
  exportTable(event) {
	if(event.target.value)
	{
	this.exportAsConfig = {
		type: event.target.value, // the type you want to download
		elementId: 'exportTable', // the id of html/table element
	}	
		//this.exportTableDirective.exportData();
    // download the file using old school javascript method
    this.exportAsService.save(this.exportAsConfig, 'Customer-Outstanding ');
    // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
    this.exportAsService.get(this.config).subscribe(content => {
    
    });
  }
  }
  
   // Print search data
	printComponent() {
		var printContents = document.getElementById("printData").innerHTML;
		var popupWin = window.open('', '_blank', 'width=300,height=300');
		popupWin.document.open();
		popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /><link rel="stylesheet" type="text/css" href="node_modules/ext-jquery/helper.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
		popupWin.document.close();
	}
  
}
